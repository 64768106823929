@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Lato', sans-serif;
}

h1 {
  @apply text-3xl font-extrabold ;
}

h2 {
  @apply text-2xl font-semibold;
}

h4 {
  @apply text-lg font-medium;
}

p{
    @apply text-base font-normal;
}